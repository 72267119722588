define("discourse/plugins/discourse-ai/discourse/components/ai-helper-options-list", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "truth-helpers", "discourse/components/d-button", "truth-helpers/helpers/eq", "discourse/plugins/discourse-ai/discourse/components/ai-helper-custom-prompt", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _truthHelpers, _dButton, _eq, _aiHelperCustomPrompt, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiHelperOptionsList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get showShortcut() {
      return this.site.desktopView && this.args.shortcutVisible;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <ul class="ai-helper-options">
          {{#each @options as |option|}}
            {{#if (eq option.name "custom_prompt")}}
              <AiHelperCustomPrompt
                @value={{@customPromptValue}}
                @promptArgs={{option}}
                @submit={{@performAction}}
              />
            {{else}}
              <li data-name={{option.translated_name}} data-value={{option.id}}>
                <DButton
                  @icon={{option.icon}}
                  @translatedLabel={{option.translated_name}}
                  @action={{fn @performAction option}}
                  data-name={{option.name}}
                  data-value={{option.id}}
                  class="ai-helper-options__button"
                >
                  {{#if (and (eq option.name "proofread") this.showShortcut)}}
                    <kbd class="shortcut">⌘⌥p</kbd>
                  {{/if}}
                </DButton>
              </li>
            {{/if}}
          {{/each}}
        </ul>
      
    */
    {
      "id": "qYYtSLr2",
      "block": "[[[1,\"\\n    \"],[10,\"ul\"],[14,0,\"ai-helper-options\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[41,[28,[32,0],[[30,2,[\"name\"]],\"custom_prompt\"],null],[[[1,\"          \"],[8,[32,1],null,[[\"@value\",\"@promptArgs\",\"@submit\"],[[30,3],[30,2],[30,4]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,\"li\"],[15,\"data-name\",[30,2,[\"translated_name\"]]],[15,\"data-value\",[30,2,[\"id\"]]],[12],[1,\"\\n            \"],[8,[32,2],[[16,\"data-name\",[30,2,[\"name\"]]],[16,\"data-value\",[30,2,[\"id\"]]],[24,0,\"ai-helper-options__button\"]],[[\"@icon\",\"@translatedLabel\",\"@action\"],[[30,2,[\"icon\"]],[30,2,[\"translated_name\"]],[28,[32,3],[[30,4],[30,2]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[32,4],[[28,[32,0],[[30,2,[\"name\"]],\"proofread\"],null],[30,0,[\"showShortcut\"]]],null],[[[1,\"                \"],[10,\"kbd\"],[14,0,\"shortcut\"],[12],[1,\"⌘⌥p\"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@options\",\"option\",\"@customPromptValue\",\"@performAction\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-helper-options-list.js",
      "scope": () => [_eq.default, _aiHelperCustomPrompt.default, _dButton.default, _helper.fn, _truthHelpers.and],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiHelperOptionsList;
});