define("discourse/plugins/discourse-ai/initializers/ai-helper", ["exports", "discourse/lib/plugin-api", "discourse-common/helpers/i18n", "discourse/plugins/discourse-ai/discourse/components/ai-composer-helper-menu", "discourse/plugins/discourse-ai/discourse/components/modal/diff-modal", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper"], function (_exports, _pluginApi, _i18n, _aiComposerHelperMenu, _diffModal, _showAiHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeAiHelperTrigger(api) {
    const showErrorToast = () => {
      const toasts = api.container.lookup("service:toasts");
      return toasts.error({
        duration: 3000,
        data: {
          message: (0, _i18n.default)("discourse_ai.ai_helper.no_content_error")
        }
      });
    };
    api.onToolbarCreate(toolbar => {
      const currentUser = api.getCurrentUser();
      const modal = api.container.lookup("service:modal");
      const selectedText = toolbarEvent => {
        const composerContent = toolbarEvent.getText();
        const selected = toolbarEvent.selected.value;
        if (selected && selected.length > 0) {
          return selected;
        }
        if (composerContent && composerContent.length > 0) {
          return composerContent;
        }
      };
      toolbar.addButton({
        id: "ai-helper-trigger",
        group: "extras",
        icon: "discourse-sparkles",
        title: "discourse_ai.ai_helper.context_menu.trigger",
        preventFocus: true,
        shortcut: "ALT+P",
        shortcutAction: toolbarEvent => {
          if (toolbarEvent.getText().length === 0) {
            return showErrorToast();
          }
          const mode = currentUser?.ai_helper_prompts.find(p => p.name === "proofread").id;
          modal.show(_diffModal.default, {
            model: {
              mode,
              selectedText: selectedText(toolbarEvent),
              toolbarEvent
            }
          });
        },
        condition: () => (0, _showAiHelper.showComposerAiHelper)(api.container.lookup("service:composer").model, api.container.lookup("service:site-settings"), currentUser, "context_menu"),
        sendAction: event => {
          if (toolbar.context.value.length === 0) {
            return showErrorToast();
          }
          const menu = api.container.lookup("service:menu");
          menu.show(document.querySelector(".ai-helper-trigger"), {
            identifier: "ai-composer-helper-menu",
            component: _aiComposerHelperMenu.default,
            modalForMobile: true,
            interactive: true,
            data: {
              toolbarEvent: event,
              selectedText: selectedText(event)
            }
          });
        }
      });
    });
  }
  var _default = _exports.default = {
    name: "discourse-ai-helper",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.1.0", api => {
        initializeAiHelperTrigger(api);
      });
    }
  };
});